//* -- CODE DEVELOPED BY WEBFULLSET TEAM  -- //*
//* -- OUR CONTACTS -- //*
//* -- https://webfullset.com/  -- //*
//* -- info@webfullset.com -- //*
//* -- Skype: hjvf_07 -- //*
//* -- https://www.freelancer.com/u/markupcoders --//*

const speed = 300;
const speed2 = 2000;
const body = $('body');

jQuery(window).on('load' ,function(){
    WebFullSetInit.init();
});

$(document).ready(function() {
    body.addClass('loaded')
});

let WebFullSetInit = {
    init: function(){
        this.WebFullSetStart();
        this.goTo();
    },

    WebFullSetStart: function() {
        $(".simplebox").simplebox();

        $(".select2").select2();

        let _scroll = function () {
            if ($(window).scrollTop() > 0) {
                body.addClass('scroll-page');
                //block.animate({height: h}, {queue:false, duration: speed, complete: function () {}})
            }
            else {
                body.removeClass('scroll-page');
            }
        };

        _scroll();

        $(window).on('scroll', _scroll);

        $('body').each(function(){
            let hold = $(this);
            let header = hold.find('#header');
            let link = hold.find('.toogle-menu');
            let box = header.find('.nav-holder');

            link.click(function(){
                if(!header.hasClass('open')){
                    setTimeout(function(){
                        header.addClass('open');
                    }, 50);
                    box.slideDown(500, function () {
                        $('body').css({overflow: 'hidden'});
                    });
                }
                else{
                    box.slideUp(500, function(){
                        header.removeClass('open');
                    });
                    $('body').css({overflow: 'visible'});
                }
                return false;
            });

            $(window).bind('closeNav', function(){
                if(header.hasClass('open')){
                    box.slideDown(500, function(){
                        header.removeClass('open');
                    });
                    $('body').css({overflow: 'visible'});
                }
            });

            $(window).bind('resize', function(){
                if(header.hasClass('open')){
                    box.slideDown(500, function(){
                        header.removeClass('open');
                    });
                    $('body').css({overflow: 'visible'});
                }
            });
        });
    },

    goTo: function(){
        $('body').each(function(){
            let hold = $(this);
            let header = hold.find('#header');
            let box = header.find('.nav-holder');
            let link = hold.find('#nav .menu a');
            let linkTop = hold.find('.btn-up');
            let linkTopWidth = linkTop.outerWidth();
            let closeNav = hold.find('.toogle-menu');
            let to;
            let position, windH;

            linkTop.click(function(){
                $('html, body').animate({scrollTop: 0}, {queue:false, duration: 1300});

                return false;
            });

            link.click(function(){
                if($(this).attr('href').substring(0,2) === '/#' ){

                    if(header.hasClass('open')){
                        box.slideUp(500, function(){
                            header.removeClass('open');
                        });
                        $('body').css({overflow: 'visible'});
                    }

                    to = hold.find($(this).attr('href').substring(1, $(this).attr('href').length)).offset().top;

                    $('html, body').animate({scrollTop: to}, {queue:false, duration: 1300});

                    console.log(555, header, closeNav);
                    if(header.hasClass('open')){
                        closeNav.trigger('click');
                    }

                    return false;
                }
            });

            function all(){
                position = $(window).scrollTop();
                windH = $(window).height();

                if(position > windH/2){
                    if(!linkTop.hasClass('visible')){
                        linkTop.addClass('visible');
                    }
                }
                else {
                    if(linkTop.hasClass('visible')){
                        linkTop.removeClass('visible');
                    }
                }
            }
            all();
            $(window).resize(function(){
                all();
            });
            $(window).scroll(function(){
                all();
            })
        });
    }
};
